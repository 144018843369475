<template>
    <Pop ref="pop" :clickClose="true" class="itemPop">
        <div slot="popContent">
            <div class="title">选择品类</div>
            <div class="itemContent">
                <div
                    class="item"
                    v-for="(item, index) in waimaiList"
                    :key="index"
                    @click="handle(item)"
                >
                    <div class="icon-content" :class="'icon-'+item.sourceCode">
                        <Svgs color="#fff" :name="item.icon"></Svgs>
                    </div>
                    <div class="subTitle">{{item.txt}}</div>
                </div>
            </div>
            <div class="cancelBtn" @click="open">取消</div>
        </div>
    </Pop>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import mealDdianPing from '@/components/Meal/mealDdianPing.js'
import { baseUrl } from '@/service/baseUrl.js'
import {
    mapGetters,
    mapMutations
} from 'vuex'
export default {
    data() {
        return {
            waimaiList: [
                {
                    txt: '美团',
                    icon: 'iconmeituan1',
                    sourceCode: 'DianPing'
                }, {
                    txt: '美餐',
                    icon: 'iconmeican',
                    sourceCode: 'MeiCan'
                }, {
                    txt: '饿了么',
                    icon: 'iconelemo',
                    sourceCode: 'ELE'
                }
            ]
        }
    },
    mixins: [mealDdianPing],
    computed: {
        ...mapGetters([
            'orderDetail',
            'event'
        ])
    },
    methods: {
        ...mapMutations([
            'FRESH_LIST'
        ]),
        open() {
            
            this.$refs.pop.changePop();
        },
        handle(item) {
            const params = {
                itemId: this.orderDetail.itemId,
                sourceCode: item.sourceCode,
                proposalId: this.event.proposalId,
                itemName: item.txt + '外卖'
            }
            miceService.saveTakeOutItem(params).then(res => {
                if (res && res.success) {
                    switch (item.sourceCode) {
                        case "DianPing":
                            this.handleDianPing()
                            break;
                        case "MeiCan":
                            this.handleMeiCan()
                            break;
                        case "ELE":
                            // this.handleEle()
                            break;
                    }
                    this.open()
                }
            });
            
        },
        handleDianPing() {
            const params = {
                itemId: this.orderDetail.itemId
            }
            this.openTakeoutDianPing(params, () => {
                miceService.refreshDianPingOrder(params).then(res => {
                    if (res && res.success) {
                        this['FRESH_LIST'](true)
                    }
                })
            })
        },
        handleMeiCan() {
            let url = `${baseUrl.iWaimaiUrl}/businessList?itemId=${this.orderDetail.itemId}&mcOnly=1&appType=iMeal&ProposalId=${this.orderDetail.proposalId}&rnd=${new Date().getTime()}`
            this.openIwaimai(url)
        },
        handleEle() {
            miceService.addEmployee().then(res => {
                if(res && res.success) {
                    let url = `${baseUrl.iWaimaiUrl}/ele/shopList?itemId=${this.orderDetail.itemId}&mcOnly=1&appType=iMeal&ProposalId=${this.orderDetail.proposalId}&rnd=${new Date().getTime()}`
                    this.openIwaimai(url)
                }
            })
        },
        openIwaimai(url) {
            let tenant_code = this.event.tenant_code ? `&tenant_code=${this.event.tenant_code}` : ''
            if(this.event.dataCode) {
                window.location.href = url + tenant_code + '&isWeb=true'
            } else {
                const options = {
                    loadUrl: url + tenant_code,
                    callback: function () {
                        this['FRESH_LIST'](true)                 
                    }
                }
                this.iJsBridge.openWebapp(options)
            }
        },
    }
};
</script>
<style lang="scss">
@import "ItemPop";
</style>
