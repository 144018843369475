<template>
    <Pop class="AdPop" ref="pop" :clickClose="true">
        <div slot="popContent">
            <div class="pop-header" @click="openPop">
                <Svgs color="#13161B" name="iconguanbi"></Svgs>
            </div>
            <div class="pop-context">
                <div class="title">百万餐厅来袭</div>
                <div class="sub-title">2020年11月06日</div>
                <div class="card1">
                    <p>
                        <img
                            class="point"
                            src="../../assets/img/ad/point@2x.png"
                        />
                        <br />
                        1.依托支付宝庞大的商户覆盖能力，丰富您的选择
                        <br />
                        2.平台集中支付，免垫支，免报销，让您专心拜访客户
                        <img
                            class="point"
                            src="../../assets/img/ad/point@2x.png"
                        />
                    </p>
                    <div class="img-container">
                        <img src="../../assets/img/ad/light@2x.png" />
                    </div>
                </div>
                <img class="img-title1" src="../../assets/img/ad/tit1@2x.png" />
                <div class="card2">
                    <div class="img-container">
                        <img src="../../assets/img/ad/iphone1@2x.png" />
                    </div>
                    <p>新增大量优选餐厅</p>
                </div>
                <img class="img-title2" src="../../assets/img/ad/tit2@2x.png" />
                <div class="card3">
                    <img src="../../assets/img/ad/iphone2@2x.png" />
                    <div>
                        通过支付宝的【扫一扫】或【付款码】方式，支付更简单便捷
                    </div>
                </div>
                <img class="img-title3" src="../../assets/img/ad/tit3@2x.png" />
                <ul>
                    <li>
                        <p>1、饿了么堂食订单如何支付？</p>
                        <p>
                            系统会唤起设备中的支付宝进行支付，
                            故用户需提前安装并登陆支付宝
                        </p>
                    </li>
                    <li>
                        <p>2、使用个人支付宝会不会扣自己钱？</p>
                        <p>
                            不会。
                            在确认支付前，会有相关确认提示：“个人付款¥0.00”，“企业支付
                            xx元”
                        </p>
                    </li>
                    <li>
                        <p>3、利用支付宝app支付，系统有何要求？</p>
                        <p>支付宝账号通过【实名认证】即可</p>
                    </li>
                    <li>
                        <p>4、如何实名认证支付宝？</p>
                        <p>
                            绑定一张银行卡 或者
                            在支付宝【我的】--【个人信息】--填写身份信息
                        </p>
                    </li>
                </ul>
                <div class="food-btn">
                    <div @click="openPop">关闭</div>
                    <div @click="addMealDemand">添加堂食订单</div>
                </div>
            </div>
        </div>
    </Pop>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import { miceService } from "@/service/miceService.js";
export default {
    computed: {
        ...mapGetters([
            'event'
        ]),
    },
    methods: {
        openPop() {
            this.$refs.pop.changePop();
        },
        addMealDemand() {
            let params = {
                miceId: this.event.proposalId,
                itemType: 5,
            }
            miceService.checkItemTypeLocked(params).then(res => {
                if(res && res.success) {
                    this.$router.push({
                        path: "/addMealDemand?shopType=平台到餐",
                    });
                }
            })
        },
    },
};
</script>
<style lang="scss">


.AdPop {
    .popContent {
        top: 0;
        background: #fff;
        padding: 0.4rem 0;
        box-sizing: border-box;

        .pop-header {
            position: absolute;
            top: 0;
            height: 0.4rem;
            width: 40%;
            right: 0;
        }

        .icon {
            position: absolute;
            right: 0.25rem;
            top: 0.12rem;
        }

        .pop-context {
            position: absolute;
            top: 0.4rem;
            width: 100%;
            bottom: 0;
            overflow: scroll;
            padding: 0 0.25rem;
            box-sizing: border-box;
            line-height: 0.24rem;

            p,
            .title,
            .sub-title {
                text-align: left;
            }

            .title {
                font-size: 0.3rem;
                line-height: 0.4rem;
            }

            .sub-title {
                color: #90939a;
            }

            .card1 {
                display: flex;
                justify-content: space-between;
                margin: 0.48rem 0 0.5rem 0;

                p {
                    width: 60%;

                    .point {
                        width: 0.12rem;
                    }
                }

                .img-container {
                    width: 36%;
                    img {
                        width: 100%;
                    }
                }   
                
            }

            .img-title1 {
                width: 0.93rem;
            }
            .card2 {
                display: flex;

                .img-container {
                    width: 56%;

                    img {
                        width: 100%;
                    }
                }

                p {
                    width: 44%;
                    padding-top: 0.25rem;
                }
            }
            .img-title2 {
                width: 0.78rem;
            }
            .card3 {
                text-align: left;
                position: relative;
                img {
                    width: 89%;
                }
                div {
                    position: absolute;
                    right: 0;
                    top: 0.25rem;
                    width: 44%;
                    text-align: left;
                }
            }
            .img-title3 {
                width: 1.81rem;
                margin: 0.1rem 0 0.3rem;
            }

            li {
                p:first-child {
                    font-size: 0.16rem;
                }

                p:last-child {
                    color: #90939a;
                    margin: 0.18rem 0 0.31rem 0;
                }
            }
            .food-btn {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.4rem;
                div {
                    width: 45%;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    border-radius: 0.25rem;
                }
                div:first-child {
                    background-color: #f5f5f5;
                }
                div:last-child {
                    color: #fff;
                    background: var(--themeColor);
                }
            }
        }
    }
}
</style>