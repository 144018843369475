<template>
    <div class="Supermarket">
        <div class="eatingContent" v-for="(item, index) in marketList" :key="'takeout'+index">
            <div class="eatingHead" :style="item.itsButtons.length?'border-bottom: 1px solid #E6E7E9;':''">
                <div class="eatingImg">
                    <div>
                        <svg class="icon" aria-hidden="true">
                            <use fill="#fff" xlink:href="#iconshop" />
                        </svg>
                    </div>
                    <!-- <img :src="item.shopImg || require('../../assets/img/logo.png')"> -->
                </div>
                <div class="eatingShop">
                    <div class="title">
                        <p class="eatingShopName">{{item.oneDish || '尚未选择商品'}}</p>
                        <div class="status" :class="computer_status(item.status)">{{item.statusTxt}}</div>
                    </div>
                    <p  v-if="item.status != 0"
                        class="eatingShopDate"
                    >您选择了 {{item.oneDish}} 等商品</p>
                    <!-- <p  v-if="item.status != 0"
                        class="eatingShopDate"
                    >您选择了 {{item.oneDish}} 等{{item.dishCount}}件商品</p> -->
                    <p 
                        class="bookingName"
                    >
                        订餐人姓名
                        <span>{{item.creatorDspName}}</span>
                        &emsp;预计金额
                        <span>￥{{item.amount | amount}}</span>
                    </p>
                    <p v-if="item.status != 0"
                        class="bookingName"
                    >
                        实际金额
                        <span>￥{{item.settle | amount}}</span>
                    </p>
                </div>
            </div>
            <div class="mealBtnRow" v-if='item.itsButtons.length'>
                <div class="btn-more" v-if="(item.itsButtons.slice().reverse().filter(item => item.processor != 'refreshOrder')).length>4" @mouseover="show(index)" @mouseout="hide(index)">
                    <!-- <Svgs name="icongengduo1"></Svgs> -->
                    <span style="color: #90939A">更多</span>
                    <ul class="more-container" :class="`btn-more-out${index}`">
                        <template v-for="(but, i) in (item.itsButtons.filter(item => item.processor != 'refreshOrder'))">
                            <li v-if="i>2" @click="handleClick(but, item)" :key="i">{{but.txt}}</li>
                        </template>
                    </ul>
                </div>
                <template v-for="(but, i) in (item.itsButtons.slice().reverse().filter(item => item.processor != 'refreshOrder'))">
                    <Btn
                        :btnClass="'mealOperation '"
                        :key="i"
                        v-if="i < 4" 
                        @callBack="handleClick(but, item)"
                    >
                        <span slot="btnContent">
                            {{but.txt}}
                        </span>
                    </Btn>
                </template>
            </div>
        </div>
        <ItemPop ref="itemPop" :itemList="itemList" @openAd="openAd" />
        <AdPop ref="AdPop" />
        <MessageBox ref="remove" type="tips" messageTxt="确认删除?" @onOk="remove"></MessageBox>
        <MessageBox ref="cancel" type="tips" messageTxt="确认取消?" @onOk="cancelOrder"></MessageBox>
        <MessageBox ref="JDtxt" type="tips" messageTxt='若商品未配送请到订单详情页取消或请致电京东客服热线人工取消"400-606-9068"' cancelTxt="拨打电话" okTxt="进入售后" showCloseBtn="true" @onCancel="telPhone" @onOk="JDcancelOrder"></MessageBox>
        <MessageBox ref="createOrder" type="tips" messageTxt="请注意外卖送餐地址只能选择会议城市" @onOk="goWaimai"></MessageBox>
        <MessageBox ref="meicanCancel" type="tips" :messageTxt="msg" @onOk="meicanCancel"></MessageBox>
    </div>
</template>
<script>
import Supermarket from "./Supermarket.js";
export default Supermarket;
</script>
<style lang="scss">

.Supermarket {
    
    .eatingContent + .eatingContent {
        margin-top: 0.14rem;
    }
    .eatingContent {
        border-radius: .1rem;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.15);
        padding: .12rem .12rem .02rem .12rem;
        .eatingHead {
            padding-bottom: .1rem;
            display: flex;

            .eatingImg,
            .eatingShop {
                float: left;
            }

            .eatingImg {
                width: 12%;
                height: .35rem;
                div{
                    width: .35rem;
                    height: .35rem;
                    border-radius: 50%;
                    background: var(--themeColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon{
                        width: .2rem;
                        height: .2rem;
                        color:#fff
                    }
                }
            }

            .eatingShop {
                width: 87%;
                padding-left: 1%;
                .eatingShopName {
                    font-size: .16rem;
                    line-height: .3rem;
                    font-weight: bold;
                    color: #13161A;
                    text-align: left;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                }
                .eatingShopDate {
                    font-size: .12rem;
                    color: #13161B;
                    text-align: left;
                }
                .title{
                    display: flex;
                    justify-content: space-between;
                    .status{
                        font-size: .13rem;
                        white-space: nowrap;
                        text-align: right;
                    }
                }
                .bookingName{
                    text-align: left;
                    color: #909399;
                    span{
                        color: #13161A;
                    }
                }
            }
        }
        .eatingHead:after,
        .mealBudget:after,
        .mealBtnRow:after {
            display: block;
            clear: both;
            content: "";
            visibility: hidden;
            height: 0;
        }

        .shopDetail,
        .mealBudget {
            border-bottom: 1px solid #E6E7E9;
            padding: .15rem 0;
            font-size: .12rem;
        }

        .shopDetail {
            .shopPhone {
                color:  var(--themeColor);
                font-weight: 500;
                text-align: left;
                font-size: .14rem;
            }

            .shopAddress {
                color: #909399;
                text-align: left;
                font-size: .12rem;
            }
        }

        .mealBudget {
            border-bottom: 1px solid #e6e7e9;
            padding: .1rem 0 .15rem 0;
            li {
                color: #909399;
                text-align: left;
                margin-top: .05rem;
            }

            .row-flex {
                display: flex;
                div {
                    width: 50%;
                    text-align: left;
                }
            }

            span {
                color: #13161a;
                margin-left: .02rem;
                font-size: 0.12rem;
            }

            .userPhone {
                color:  var(--themeColor);
                font-weight: 500;
            }
        }

        .mealBtnRow {
            padding: .12rem 0 0.1rem;
            display: flex;
            justify-content: flex-end;
            position: relative;

            .btn-more {
                position: absolute;
                left: 0;
                bottom: 0.18rem;
            }
            .more-container {
                position: absolute;
                left: -0.1rem;
                top: 0.3rem;
                z-index: 1;
                background: #fff;
                box-shadow: 1px 1px 3px #e6e7e9;
                border-radius: 0.08rem;
                display: none;
                
                li {
                    height: 0.46rem;
                    line-height: 0.46rem;
                    width: 1.1rem;
                }
                li + li {
                    border-top: 1px solid #e6e7e9;
                }
            }
            .mealOperation {
                height: .25rem;
                line-height: .25rem;
                padding: 0 .05rem;
                min-width: .4rem;
                text-align: center;
                position: relative;
                margin-left: .06rem;
                border-radius: .05rem;
                font-size: 0.12rem;
                color: #90939A;
                border: 1px solid #E6E7E9;
                background: #fff;
                .mealOperationBtn {
                    width: .2rem !important;
                    height: .2rem !important;
                    position: absolute;
                    left: .15rem;
                    top: .15rem;
                }
            }
            span:last-child{
                .mealOperation{
                    background: var(--themeColor);
                    color: #fff;
                }
            }
            .btnHighlight{
                background: var(--themeColor);
                color: #fff;
            }
            
        }
    }
}
</style>
