import JiaoBiao from "@/components/OrderList/JiaoBiao.vue";
import { miceService } from "@/service/miceService.js";
import { baseUrl } from '@/service/baseUrl.js'
import mealDdianPing from '@/components/Meal/mealDdianPing.js'
import {
    mapGetters,
    mapMutations
} from 'vuex'
import { setCookie, getCookie } from 'tiny-cookie'
import {
    SmartStorage
} from 'smart-core-util'
import { getAllCookies } from 'tiny-cookie'
import { indicator } from 'smart-indicator'
import ItemPop from "@/components/OrderList/ItemPop.vue";
import AdPop from "@/components/OrderList/AdPop.vue";
export default {
    name: "Supermarket",
    props: ["marketList"],
    mixins: [mealDdianPing],
    components: {
        JiaoBiao,
        ItemPop,
        AdPop
    },
    data() {
        return {
            msg: '',
            optionalType: null,
            itemList: []
        }
    },
    computed: {
        ...mapGetters([
            'orderDetail',
            'event',
            'address',
            'memsg',
            'mealRules'
        ])
    },
    async created(){
      
    },
    methods: {
        ...mapMutations([
            'ORDER_DETAIL',
            'FRESH_LIST'
        ]),
        computer_status(status){
            if([1,4,5,-1].includes(status)){
                return "colorStatus_" + `${JSON.stringify(status)}`
            }else{
                return "colorStatus_default"
            }
        },
        openAd(){

        },
        show(i) {
            var icon = document.getElementsByClassName(`btn-more-out${i}`)[0];
            icon.style.display = "inline-block";
        },
        // 隐藏 remove-icon
        hide(i) {
            var icon = document.getElementsByClassName(`btn-more-out${i}`)[0];
            icon.style.display = "none";
        },
        handleClick(btn, item) {
            if (btn.forbidden) {
                this.toast(btn.msg);
                return;
            }
            this['ORDER_DETAIL'](item);
            let buttonType = btn.processor;
            switch (buttonType) {
                //编辑
                case "edit":
                    this.$router.push({
                        path: '/addSuperMarket',
                        query: {
                            isEdit: true,
                            qty1: item.qty1,
                            amount: item.amount,
                            itemData: item.itemData,
                            itemId: item.itemId
                        }
                    })
                    break;
                //删除    
                case "remove":
                    this.$refs.remove.openPop();
                    break;
                //创建                   
                case "createOrder":
                    
                    this.createOrder(item,btn)
                    break;
                //确认订单    
                case "confirmReceipt":
                    this.confirmReceipt()
                    break;
                //京东确认订单    
                case "confirmOrder":
                    this.confirmOrder(item)
                    break;
                //上传发票    
                case "uploadInvoice":
                    this.$router.push({
                        path: '/uploadInvoice'
                    })
                    break;
                //查看详情
                case "viewDetails":
                    if(item.soucreCode == 'JD'){
                        this.goJD(item,'2')
                        return
                    }
                    if (['DianPing'].includes(item.sourceCode)) {
                        this.openDdianPing()
                        return
                    }
                    if (item.sourceCode == 'ELE' && item.isH5 == true && item.status != 5 && item.extOrderId) {
                        this.openEleDetails(item)
                        return
                    }
                    // if (['ELE', 'XiaoMiShu'].includes(item.sourceCode)) {
                        this.$router.push({ path: '/takeOutEleDetail', query: { sourceCode: item.sourceCode } })
                    // } else {
                    //     this.$router.push({ path: '/takeoutOrderDetail' })
                    // }
                    break;
                // JD查看详情
                case "JDDetails":
                    this.$router.push({ path: '/superMarketDetail' })
                    break;
                //取消    
                case "cancelOrder":
                    this.$refs.cancel.openPop();
                    break;
                //京东取消    
                case "JDcancelOrder":
                    this.$refs.JDtxt.openPop();
                    break;
                //选择商家    
                case "selectMerchant":
                    // this.openDdianPing()
                    this.createOrder(item,btn)
                    break;
                //更新文件
                case "updateFile":
                    this.$router.push({
                        path: '/updateFile',
                        query: {
                            itemType: 7
                        }
                    })
                    break;
                //饿了么再支付
                case "reToPay":
                    this.reToPay()
            }
        },
        //京东售后电话
        telPhone(){
            this.ihtml.telCall('4006069068')
        },
        // 京东取消/售后
        JDcancelOrder(){
            this.goJD(this.orderDetail,'3')
        },
        remove() {
            var params = {
                itemId: this.orderDetail.itemId
            }
            miceService.deleteTakeoutItem(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)
                }
            })
        },
        goJD(item,loginType){
            let params = {
                itemId: item.itemId,
                itemType: item.itemType,
                loginType: loginType,
                amount: item.amount,
                peopleNum: item.qty1,
                userName: this.memsg.dspName,
                phone: this.memsg.phoneNumber,
                activityCode: this.mealRules.activityCode,
            }
            miceService.JDH5Login(params).then(res => {
                let content = res.content
                var ua = navigator.userAgent.toLowerCase();
                // 企业微信环境
                if(/micromessenger/i.test(ua)){
                    this.openPostWindow(content, '_self');
                }else{
                    this.openPostWindow(content, '_self');
                }
            })
        },
        async createOrder(item,btn) {
            if(this.event.tenant_code != 'roche'){
                await this.execute(this.orderDetail)
            }
            if(btn.checkurl){
                await this.takeOutCheck(item,btn)
            }
            if(this.mealRules.marketSupplier && this.mealRules.marketSupplier.length == 1){
                switch (this.mealRules.marketSupplier[0]) {
                    case 'JD':
                        this.goJD(item,'1')
                        break;
                    case 'ELE':
                        this.goWaimai()
                        break;
                }
                return
            }
            if(['会引擎-huarun','会引擎-恩必普'].includes(this.event.tenant_name)){
                this.goJD(item,'1')
            }else{
                this.goWaimai()
            }
            
        },
        execute(item) {
            let params = {
                NodeCode:"CheckCanOrder",
                ContextDataKeys:{
                    ProposalId: item.proposalId,
                    ItemId: item.itemId
                }
            }
            return new Promise((resolve, reject) => {
                miceService.execute(params).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        openPostWindow(data, name){
            // 创建 form 表单
            let tempForm = document.createElement('form');
            tempForm.id = 'tempForm';
            tempForm.action = data.loginUrl;
            tempForm.method = 'post';
            // tempForm.enctype = 'multipart/form-data';
            tempForm.target = name;
            
            let accessKey = document.createElement('input');
            accessKey.type = 'hidden';
            accessKey.name = 'accessKey';
            accessKey.value = data.accessKey;
            tempForm.appendChild(accessKey);
            
            let extend = document.createElement('input');
            extend.type = 'hidden';
            extend.name = 'extend';
            extend.value = data.extend;
            tempForm.appendChild(extend);
            
            let returnUrl = document.createElement('input');
            returnUrl.type = 'hidden';
            returnUrl.name = 'returnUrl';
            returnUrl.value = data.returnUrl;
            tempForm.appendChild(returnUrl);
            
            let sign = document.createElement('input');
            sign.type = 'hidden';
            sign.name = 'sign';
            sign.value = data.sign;
            tempForm.appendChild(sign);
            
            let timestamp = document.createElement('input');
            timestamp.type = 'hidden';
            timestamp.name = 'timestamp';
            timestamp.value = data.timestamp;
            tempForm.appendChild(timestamp);
            
            let uid = document.createElement('input');
            uid.type = 'hidden';
            uid.name = 'uid';
            uid.value = data.uid;
            tempForm.appendChild(uid);
            
            let version = document.createElement('input');
            version.type = 'hidden';
            version.name = 'version';
            version.value = data.version;
            tempForm.appendChild(version);

            // 将 form 表单插入 body
            document.body.appendChild(tempForm);
        
            // 增加提交监听 处理浏览器的兼容性
            if (window.attachEvent) {
                tempForm.attachEvent('onsubmit', () => { window.open(data.loginUrl, name); });
            } 
            // else if (window.addEventListener) {
            //     tempForm.addEventListener('onsubmit', () => { window.open(data.loginUrl, name); });  
            // }
        
            // 触发监听 处理浏览器的兼容性
            if(tempForm.fireEvent){
                tempForm.fireEvent('onsubmit');
            }else{
                let evt = document.createEvent('HTMLEvents');
                evt.initEvent('onsubmit', true, true);
                tempForm.dispatchEvent(evt);
            }
        
            // form 表单提交事件
            tempForm.submit();
            // 从 body 中移除 form 表单
            document.body.removeChild(tempForm);
        },
        
        takeOutCheck(item,btn){
            return new Promise((resolve,reject) => {
                let params = {
                    qty1: item.qty1,
                    Amount: item.amount,
                    ProposalId: item.proposalId,
                    ItemType: item.itemType,
                    ItemId: item.itemId,
                }
                miceService.common(btn.checkurl,params).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                })
            })
        },
        async goWaimai() {
            let item = this.orderDetail
            let open = null
            if (SmartStorage.get("isH5")) {
                open = window.open("", "_blank")
            }
            // let showMeituan = false;
            let _this = this;
            // let mealCombineData = []
            // let open = null
            // if (SmartStorage.get("isH5")) {
            //     open = window.open("", "_blank")
            // }
            // 获取饿了么H5地址
            // if (this.optionalType.includes('饿了么H5')) {
                let eleH5Url = ''
                let address = {}
                if (['novartis'].includes(this.event.tenant_code)) {
                    address = JSON.parse(item.itemData).Hospital
                } else {
                    if(!SmartStorage.get("isH5")){
                        address = await this.getCurrentPosition()
                    }
                }
                let eleH5params = {
                    itemId: item.itemId,
                    UserPhone: this.memsg.phoneNumber,
                    Longitude: address.longitude || '',// 104.062742
                    Latitude: address.latitude || '',//30.578242
                    building: address.name || address.hospitalName || '', //新中泰国际大厦A座
                    Email: this.memsg.email || '',
                    DspName: this.memsg.dspName || '',//dspName
                    sourceCode: 'ELE'
                }
                let eleH5Res = await this.getElePingUrl(eleH5params)
                if (eleH5Res && eleH5Res.success) {
                    eleH5Url = eleH5Res.content
                    if (SmartStorage.get("isH5")) {
                        setTimeout(() => {
                            open.location = eleH5Url
                        }, 100)
                        return
                    }
                }

            // }
            // await this.addEmployee()
            // if (mealCombineData.length == '1') {
                let tenant_code = this.event.tenant_code || 'eventcool'
                const options = {
                    method: "SMGeneral.openNewWebSiteForResult",
                    postData: {
                        loadUrl: eleH5Url,
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: this.navBackgroundColor[tenant_code]
                        }
                    },
                    callback: (viewData) => {
                        _this['FRESH_LIST'](true)
                    }
                };
                this.iJsBridge.call(options);
                return
            // }
            // const options = {
            //     method: "SMGeneral.openNewWebSiteForResult",
            //     postData: {
            //         loadUrl: 'mealCombineData',
            //         cookieData: getAllCookies(),
            //         mealCombineData: mealCombineData,
            //     },
            //     callback: (viewData) => {
            //         _this['FRESH_LIST'](true)
            //     }
            // }
            // this.iJsBridge.call(options)
        },
        // 地图定位
        getCurrentPosition() {
            indicator.open()
            let _this = this
            return new Promise((resolve, reject) => {
                var map = new AMap.Map("container", {});
                AMap.plugin("AMap.Geolocation", () => {
                    /* global AMap */
                    var geolocation = new AMap.Geolocation({
                        timeout: 3000, //超过n秒后停止定位，默认：5s
                        useNative: true,
                        enableHighAccuracy: true, //是否使用高精度定位，默认:true
                        extensions: 'all'
                    });
                    map.addControl(geolocation);
                    geolocation.getCurrentPosition((status, result) => {
                        if (status == "complete") {
                            console.log('定位成功', status, result)
                            let city = result.addressComponent.city || result.addressComponent.province;
                            let address = {
                                latitude: result.position.lat,
                                longitude: result.position.lng,
                                name: (result.pois || [{ name: '' }])[0].name || result.formattedAddress,
                                city: city
                            }
                            resolve(address)
                        } else {
                            console.log('定位失败', status, result)
                            resolve(result)
                        }
                        indicator.close()
                    })
                })
            })
        },
        // 根据城市查询是否有美团
        getSort() {
            let tenant_code = this.event.tenant_code || "ipsen";
            let params = {
                collection: "cfg-iwaimai-sort-options",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: { $in: [tenant_code || "*", "*"] },
                    eventType: { $in: [this.event.typeDictTxt || "*", "*"] },
                    eventStatus: { $in: [this.event.statusTxt || "*", "*"] },
                    eventPlace: { $in: ["*", "*"] },
                    "cities.city": {
                        $in: [this.event.cityDictVal || "*", "*"],
                    },
                },
                projection: {
                    name: 1,
                    handle: 1,
                    icon: 1,
                    order: 1,
                    id: 1,
                },
            };
            return new Promise((resolve, reject) => {
                miceService.getOptItems(params).then(res => {
                    if (res && res.success) {
                        resolve(res.content)
                    } else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取美团地址
        getDianPingUrl(data) {
            return new Promise((resolve, reject) => {
                miceService.getDianPingUrl(data).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取饿了么H5地址
        getElePingUrl(data) {
            return new Promise((resolve, reject) => {
                miceService.getElePingUrl(data).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        saveCacheData(item) {
            SmartStorage.set("eventData", JSON.stringify(this.event), {
                domain: window.idomain
            });
            SmartStorage.set("eventData", JSON.stringify(this.event));
            // 数据持久化保存数据
            let eventDataParams = {
                Data: {
                    eventData: this.event || {},
                    token: getCookie('token'),
                    proposalId: this.event.proposalId,
                    tenant_id: getCookie('tenant_id'),
                    tenant_code: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    tenant: SmartStorage.get('tenant_code') || this.event.tenant_code,
                    itemId: item.itemId,
                    noBack: false,
                    container: SmartStorage.get("container") || '',
                    paramCode: false,
                }
            }
            if (SmartStorage.get('isH5')) {
                eventDataParams.Data.isH5 = SmartStorage.get('isH5')
                eventDataParams.Data.type = SmartStorage.get('type')
            }
            if (item.hospitalGPS) {
                eventDataParams.Data.eventData.hospitalGPS = item.hospitalGPS
            }
            return new Promise((resolve, reject) => {
                miceService.saveCacheData(eventDataParams).then(sessionRes => {
                    if (sessionRes && sessionRes.success) {
                        resolve(sessionRes)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        addEmployee() {
            return new Promise((resolve, reject) => {
                miceService.addEmployee({ SourceCode: "ELE" }).then(res => {
                    if (res && res.success) {
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        confirmOrder(item) {
            let params = {
                itemId: item.itemId,
                activityCode: this.mealRules.activityCode,
            }
            miceService.confirmOrder(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)   
                }
            })
        },
        confirmReceipt() {
            this.$router.push({
                path: '/uploadInvoice'
            })
        },
        cancelOrder() {
            let params = {
                itemId: this.orderDetail.itemId
            }
            if (this.orderDetail.sourceCode == 'ELE' && this.orderDetail.isH5 == true && this.orderDetail.status != 5 && this.orderDetail.extOrderId) {
                this.openEleDetails(this.orderDetail)
                return
            }
            // 美团的取消
            if (this.orderDetail.status > 0 && this.orderDetail.sourceCode == 'DianPing') {
                this.openDdianPing()
                return;
            } else if (['ELE', 'MeiCan'].includes(this.orderDetail.sourceCode)) {
                // 饿了么取消
                miceService.eleTakeoutCancel(params).then(res => {
                    if (res && res.success) {
                        if (res.code == 201) {
                            if (SmartStorage.get("isH5")) {
                                window.location.href = res.content.buttonUrl
                                return
                            }
                            this.openTakeoutEle(res.content.buttonUrl, () => {
                                this['FRESH_LIST'](true)
                                return
                            })
                        } else if ((res.content || {}).msg) {
                            this.msg = res.content.msg
                            this.$refs.meicanCancel.openPop();
                        } else {
                            this['FRESH_LIST'](true);
                            return
                        }

                    }
                })
            } else {
                miceService.cancelTakeoutItem(params).then(res => {
                    if (res && res.success) {
                        this['FRESH_LIST'](true);
                    }
                })
            }
        },
        async openEleDetails(item) {
            let _this = this
            const params = {
                OrderNo: item.extOrderId,
                UserPhone: this.memsg.phoneNumber
            }
            let open = null
            if(SmartStorage.get('isH5')){
                open = window.open("", "_blank")
            }
            miceService.GetOrderPageUrl(params).then(res => {
                if (res && res.success) {
                    const tenant_code = this.event.tenant_code || 'eventcool';
                    const postData = {
                        loadUrl: res.content,
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: this.navBackgroundColor[tenant_code]
                        }
                    }
                    const options = {
                        method: "SMGeneral.openNewWebSiteForResult",
                        postData: postData,
                        callback: async () => {
                            _this['FRESH_LIST'](true)
                        }
                    };
                    if(SmartStorage.get('isH5')){
                        setTimeout(() => {
                            open.location = res.content
                        }, 100)
                        return
                    }
                    this.iJsBridge.call(options);
                }
            })
        },
        openDdianPing() {
            const params = {
                itemId: this.orderDetail.itemId
            }
            this.openTakeoutDianPing(params, () => {
                miceService.refreshDianPingOrder(params).then(res => {
                    if (res && res.success) {
                        this['FRESH_LIST'](true)
                    }
                })
            })
        },
        reToPay() {
            let params = {
                orderId: this.orderDetail.extOrderId
            }
            miceService.reToPay(params).then(res => {
                if (res && res.success) {
                    this['FRESH_LIST'](true)
                }
            })
        },
        meicanCancel() {
            this['FRESH_LIST'](true);
            return
        }
    },
    filters: {
        qty1: value => {
            if (value && value >= 0) {
                return value + "人";
            } else {
                return "0人";
            }
        },
        amount: value => {
            if (value && value >= 0) {
                return parseFloat(value).toFixed(2);
            } else {
                return 0;
            }
        }
    }
};