<template>
    <div class="item5Detail">
        <div class="eatingContent" v-for="(item, index) in mealList" :key="'meal'+index">
            <div class="eatingHead">
                <div class="eatingImg">
                    <img :src="item.itsItemData.shopImg || require('../../assets/img/logo.png')">
                </div>
                <div class="eatingShop">
                    <p class="eatingShopName">{{item.itsItemData.shopName}}</p>
                    <p
                        class="eatingShopDate"
                    >{{item.dtStart | dateTime}} - {{item.dtEnd | dateTime}}</p>
                    <div class="status">{{item.statusText}}</div>
                </div>
                <!-- <JiaoBiao :item="item"/> -->
            </div>
            <div class="shopDetail"  v-if="(item.isExtra && item.itsItemData.payProfile.PayModes[0]!=7) || !item.isExtra">
                <div class="shopPhone" @click="ihtml.telCall(item.itsItemData.mobile)">{{item.itsItemData.mobile}}</div>
                <div class="shopAddress">{{item.itsItemData.address}}</div>
            </div>
            <ul class="mealBudget">
                <li class="row-flex" v-if="!['novartis'].includes(event.tenant_code)">
                    <div>
                        {{item.itemName}} 
                        <template v-if="['dscl','crossevent'].includes(event.tenant_code)">| {{item.itsItemData.mealType || '--'}}</template>
                        | 预计
                        <span>{{item.qty1 | qty1}}</span>
                    </div>
                    <div>
                        预算
                        <span>¥{{parseFloat(item.amount).toFixed(2)}}</span>
                    </div>
                </li>
                <li class="row-flex" v-if="['novartis'].includes(event.tenant_code)">
                    <div>
                        {{item.itemName}} <span v-if="item.realQty">| 实际人数
                        <span>{{item.realQty | qty1}}</span></span>
                    </div>
                    <div v-if="item.realQty">
                        实际金额
                        <span>¥{{parseFloat(item.realAmount).toFixed(2)}}</span>
                    </div>
                </li>
                <li class="row-flex">
                    <div>
                    订餐人姓名
                    <span>{{item.itsItemData.bookingName}}</span>
                    </div>
                    <div>
                        订餐人电话
                        <span class="userPhone" @click="ihtml.telCall(item.itsItemData.bookingPhoneNum)">{{item.itsItemData.bookingPhoneNum}}</span>
                    </div>
                </li>
                <li>
                    订单创建人：{{item.creatorDspName}}
                    <span v-show="item.createdBy">（{{item.createdBy}}）</span>
                </li>
            </ul>
            <div class="mealBtnRow">
                <div v-if="item.itsButtons.length>4" class="btn-more" @mouseover="show(index)" @mouseout="hide(index)">
                    <!-- <Svgs name="icongengduo1"></Svgs> -->
                    <span style="color: #90939A">更多</span>
                    <ul class="more-container" :class="`btn-more${index}`">
                        <template v-for="(but, idx) in item.itsButtons.slice().reverse()">
                            <li v-if="item.itsButtons.length <= 4 && idx < 2 || item.itsButtons.length > 4 && idx < 2" @click="handleClick(but, item)" :key="idx">{{but.txt}}</li>
                        </template>
                    </ul>
                </div>
                <template v-for="(but, idx) in item.itsButtons.slice().reverse()">
                    <Btn 
                        v-if="item.itsButtons.length <= 4 && idx < 5 || item.itsButtons.length > 4 && idx > 1"
                        :btnClass="'mealOperation ' + but.css"
                        :key="idx"
                        @callBack="handleClick(but, item)"
                    >
                        <span slot="btnContent">
                            {{but.txt}}
                        </span>
                    </Btn>
                </template>
            </div>
        </div>
        <MessageBox ref="remove" type="tips" messageTxt="确认删除?" @onOk="remove"></MessageBox>
        <MessageBox ref="cancel" type="tips" messageTxt="确认取消?" @onOk="cancelOrder"></MessageBox>
        <MessageBox ref="refund" type="tips" messageTxt="请与商家协商后，致电客服，发起电话取消?" @onOk="refund"></MessageBox>
        <MessageBox ref="uploadInvoice" type="tips" okTxt="联系客服" messageTxt="订单支付异常，请联系客服" @onOk="ihtml.telCall('01056304858')"></MessageBox>
        <MessageBox ref="chang" type="tips" btnType="single" singleTxt="确定" messageTxt="餐厅更换成功，请拨打餐厅电话预约用餐，并请和餐厅确认是否能开具发票，如不能开具请更换餐厅！"></MessageBox>
    </div>
</template>
<script>
import Item5Detail from "./Item5Detail.js";
export default Item5Detail;
</script>
<style lang="scss">

.item5Detail {
    .eatingContent + .eatingContent {
        margin-top: 0.2rem;
    }
    .eatingContent {
        background: #F8F8F8;
        border-radius: .2rem;
        box-shadow: 5px 5px .15rem #dedede;
        padding: .2rem .2rem 0 .2rem;
        position: relative;
        .eatingHead {
            border-bottom: 1px solid #E6E7E9;
            padding-bottom: .05rem;
            position: relative;

            .eatingImg,
            .eatingShop {
                float: left;
            }

            .eatingImg {
                width: 22%;
                text-align: left;
                img {
                    width: .5rem;
                    height: .5rem;
                    border-radius: 50%;
                }
            }

            .eatingShop {
                width: 65%;
                .eatingShopName {
                    font-size: .14rem;
                    font-weight: 500;
                    color: #13161A;
                    text-align: left;
                }
                .eatingShopDate {
                    font-size: 0.1rem;
                    color: #c0c4cc;
                    text-align: left;
                }
            }
        }

        .status {
            color:  var(--themeColor);
            position: absolute;
            right: 5px;
            bottom: 2px;
        }

        .eatingHead:after,
        .mealBudget:after,
        .mealBtnRow:after {
            display: block;
            clear: both;
            content: "";
            visibility: hidden;
            height: 0;
        }

        .shopDetail,
        .mealBudget {
            border-bottom: 1px solid #E6E7E9;
            padding: .15rem 0;
            font-size: .12rem;
        }

        .shopDetail {
            .shopPhone {
                color:  var(--themeColor);
                font-weight: 500;
                text-align: left;
                font-size: .14rem;
            }

            .shopAddress {
                color: #909399;
                text-align: left;
                font-size: .12rem;
            }
        }

        .mealBudget {
            border-bottom: 1px solid #e6e7e9;
            padding: .1rem 0 .15rem 0;
            li {
                color: #909399;
                text-align: left;
                margin-top: .05rem;
            }

            .row-flex {
                display: flex;
                div {
                    width: 50%;
                    text-align: left;
                }
            }

            span {
                color: #13161a;
                margin-left: .02rem;
                font-size: 0.12rem;
            }

            .userPhone {
                color:  var(--themeColor);
                font-weight: 500;
            }
        }

        .mealBtnRow {
            padding: .12rem 0 0.14rem;
            display: flex;
            justify-content: flex-end;
            position: relative;

            .btn-more {
                position: absolute;
                left: 0;
                bottom: 0.18rem;
            }
            .more-container {
                position: absolute;
                left: -0.1rem;
                top: 0.3rem;
                z-index: 1;
                background: #fff;
                box-shadow: 1px 1px 3px #e6e7e9;
                border-radius: 0.08rem;
                display: none;
                
                li {
                    height: 0.46rem;
                    line-height: 0.46rem;
                    width: 1.1rem;
                }
                li + li {
                    border-top: 1px solid #e6e7e9;
                }
            }
            .mealOperation {
                padding: 0.05rem .1rem;
                text-align: center;
                position: relative;
                box-shadow: 1px 1px 1px var(--shadowColor);
                margin-left: .06rem;
                border-radius: 0.25rem;
                font-size: 0.1rem;
                color: #90939A;
                border: 1px solid #E6E7E9;
                background: #fff;
                .mealOperationBtn {
                    width: .2rem !important;
                    height: .2rem !important;
                    position: absolute;
                    left: .15rem;
                    top: .15rem;
                }
            }
            .btnHighlight{
                background: var(--themeColor);
                color: #fff;
                border: none;
            }
            
        }
    }
    
}
</style>
