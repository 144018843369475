<template>
    <div class="item7Detail">
        <div class="eatingContent" v-for="(item, index) in takeoutList" :key="'takeout'+index">
            <div class="eatingHead">
                <div class="eatingImg">
                    <img :src="item.shopImg || require('../../assets/img/logo.png')">
                </div>
                <div class="eatingShop">
                    <p class="eatingShopName waimaiName">{{item.shopName || '未选择商户'}}</p>
                </div>
                <JiaoBiao :item="item"/>
            </div>
            <div class="info">
                <div class="dish" v-if="item.oneDish">您选择了 {{item.oneDish}} 等{{item.dishCount}}件商品</div>
                <div v-else>尚未选择商品</div>
                <div class="status">{{item.statusTxt}}</div>
            </div> 
            <div class="settle" v-if="item.status>0" style="color: #e8470b">实际金额：¥{{item.settle | amount}}</div> 
            <div v-else class="waiBudget row-flex">
                <div>
                    预计
                    <span>{{item.qty1 | qty1}}</span>
                </div>
                <div>
                    预算
                    <span>¥ {{item.amount | amount}}</span>
                </div>
            </div>
            <div class="waiBudget">
                订单创建人：{{item.creatorDspName}}
                <span v-show="item.createdBy">（{{item.createdBy}}）</span>
            </div>
            <div class="mealBtnRow">
                <div class="btn-more" v-if="(item.itsButtons.slice().reverse().filter(item => item.processor != 'refreshOrder')).length>4" @mouseover="show(index)" @mouseout="hide(index)">
                    <!-- <Svgs name="icongengduo1"></Svgs> -->
                    <span style="color: #90939A">更多</span>
                    <ul class="more-container" :class="`btn-more-out${index}`">
                        <template v-for="(but, i) in (item.itsButtons.filter(item => item.processor != 'refreshOrder'))">
                            <li v-if="i>2" @click="handleClick(but, item)" :key="i">{{but.txt}}</li>
                        </template>
                    </ul>
                </div>
                <template v-for="(but, i) in (item.itsButtons.slice().reverse().filter(item => item.processor != 'refreshOrder'))">
                    <Btn
                        :btnClass="'mealOperation ' + but.css"
                        :key="i"
                        v-if="i < 4" 
                        @callBack="handleClick(but, item)"
                    >
                        <span slot="btnContent">
                            {{but.txt}}
                        </span>
                    </Btn>
                </template>
            </div>
        </div>

        <ItemPop ref="itemPop" @handlechannel="handlechannel" :itemList="mealRules.takeOutList" @chooseHos="chooseHos" />
        <MessageBox ref="remove" type="tips" messageTxt="确认删除?" @onOk="remove"></MessageBox>
        <MessageBox ref="cancel" type="tips" messageTxt="确认取消?" @onOk="cancelOrder"></MessageBox>
        <!-- <MessageBox ref="createOrder" type="tips" messageTxt="请注意外卖送餐地址只能选择会议城市" @onOk="goWaimai"></MessageBox> -->
        <MessageBox ref="createOrder2" type="tips" :messageTxt="mealRules.takeOutMsg" @onOk="goWaimai"></MessageBox>
        <MessageBox ref="meicanCancel" type="tips" :messageTxt="msg" @onOk="meicanCancel"></MessageBox>
    </div>
</template>
<script>
import Item7Detail from "./Item7Detail.js";
export default Item7Detail;
</script>
<style lang="scss">

.item7Detail {
    .eatingContent + .eatingContent {
        margin-top: 0.2rem;
    }
    .eatingContent {
        background: #F8F8F8;
        border-radius: .2rem;
        box-shadow: 5px 5px .15rem #dedede;
        padding: .2rem .2rem 0 .2rem;
        .eatingHead {
            border-bottom: 1px solid #E6E7E9;
            padding-bottom: .05rem;
            position: relative;

            .eatingImg,
            .eatingShop {
                float: left;
            }

            .eatingImg {
                width: 22%;
                text-align: left;
                img {
                    width: .5rem;
                    height: .5rem;
                    border-radius: 50%;
                }
            }

            .eatingShop {
                width: 65%;
                .eatingShopName {
                    font-weight: 500;
                    color: #13161A;
                    text-align: left;
                    margin-top: .15rem;
                    font-size: .14rem;
                }
            }
        }

        .info {
            display: flex;
            justify-content: space-between;
            margin-top: 0.15rem;

            .dish {
                width: 2rem;
                text-align: left;
            }

            .status {
                color:  var(--themeColor);
            }
        }

        .settle {
            text-align: left;
            margin-top: 0.05rem;
        }

        .eatingHead:after,
        .mealBtnRow:after {
            display: block;
            clear: both;
            content: "";
            visibility: hidden;
            height: 0;
        }

        .waiBudget {
            padding: .05rem 0 0.15rem 0;
            font-size: .12rem;
            text-align: left;
            color: #909399;

            div {
                text-align: left;
            }

            span {
                color: #13161a;
                margin-left: 0.02rem;
                font-size: .12rem;
            }
        }

        .row-flex {
            display: flex;
            padding-bottom: 0 !important;
            div {
                width: 50%;
            }
        }

        .mealBtnRow {
            padding: .12rem 0 0.14rem;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #E6E7E9;
            position: relative;

            .btn-more {
                position: absolute;
                left: 0;
                bottom: 0.18rem;
            }
            .more-container {
                position: absolute;
                left: -0.1rem;
                top: 0.3rem;
                z-index: 1;
                background: #fff;
                box-shadow: 1px 1px 3px #e6e7e9;
                border-radius: 0.08rem;
                display: none;
                
                li {
                    height: 0.46rem;
                    line-height: 0.46rem;
                    width: 1.1rem;
                }
                li + li {
                    border-top: 1px solid #e6e7e9;
                }
            }
            .mealOperation {
                padding: 0.05rem .1rem;
                text-align: center;
                position: relative;
                box-shadow: 1px 1px 1px var(--shadowColor);
                margin-left: .1rem;
                border-radius: 0.25rem;
                font-size: 0.12rem;
                color: #90939A;
                border: 1px solid #E6E7E9;
                background: #fff;
                .mealOperationBtn {
                    width: .2rem !important;
                    height: .2rem !important;
                    position: absolute;
                    left: .15rem;
                    top: .15rem;
                }
            }
            .btnHighlight{
                background: var(--themeColor);
                color: #fff;
                border: none;
            }
        }
    }
}
</style>
