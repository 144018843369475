<template>
    <div class="order-list">
        <!-- <Header backType="gra"></Header> -->
        <Header backType="white" v-if="mealRules.newUI" :backEvent="true" @backEvent="back" :back="!fromXBH">
            <span slot="headText" style="font-size: 0.17rem">{{ marketShow ? "商超便利" : "用餐需求" }}</span>
            <span v-if="isShowBatchApprovalBtn" @click="batchApproval" slot="rightBtn"> 批量审批 </span>
        </Header>
        <Header backType="normal" v-else :svgColor="'#fff'" :backEvent="true" @backEvent="back" :back="!fromXBH"></Header>
        <div class="cardChange" v-if="mealRules.newUI && !marketShow && !fromXBH">
            <div @click="isSelectCard = 'all'" :class="isSelectCard == 'all' ? 'isSelectCard' : ''">
                <p>全部</p>
                <div v-if="isSelectCard == 'all'"></div>
            </div>
            <div @click="isSelectCard = 'meal'" :class="isSelectCard == 'meal' ? 'isSelectCard' : ''">
                <p>外出用餐</p>
                <div v-if="isSelectCard == 'meal'"></div>
            </div>
            <div @click="isSelectCard = 'takeOut'" :class="isSelectCard == 'takeOut' ? 'isSelectCard' : ''">
                <p>外卖</p>
                <div v-if="isSelectCard == 'takeOut'"></div>
            </div>
        </div>
        <ScrollBar @onTop="loadTop" :style="listStyle" :loadType="loadType">
            <div slot="scrollBar">
                <div v-if="!mealRules.newUI" class="mealHead">
                    <div class="mealTitle" v-if="['novartis'].includes(event.tenant_code)">餐饮需求</div>
                    <div v-else-if="!marketShow" class="mealTitle">用餐结算</div>
                    <ul class="mealMonthList">
                        <li :class="{ mealMonthOn: index == dateObj.monthOn }" v-for="(m, index) in dateList" @click="changeMonth(index)" :key="index">{{ m.month }}月</li>
                    </ul>
                    <ul class="mealDayList">
                        <li class="mealDayAll" :class="{ mealDayOn: dateObj.dayOn == 'all' }" @click="changeDay('all', { curDay: 'all', isForbidden: true })">全部</li>
                        <li :class="{ mealDayOn: m.curDay == dateObj.dayOn }" v-for="(m, index) in mealDayList" @click="changeDay(index, m)" :key="index">
                            <p>{{ m.daysText }}</p>
                            <p>
                                <span :style="{ opacity: m.isForbidden ? 1 : 0 }"></span>
                            </p>
                            <p class="mealDay">{{ m.days }}</p>
                        </li>
                    </ul>
                </div>
                <div style="height: 0.3rem; width: 100%" v-if="!marketShow && !fromXBH"></div>
                <AttentionTips v-if="['roche'].includes(event.tenant_code)" :tips="tips" :newUI="true" />

                <div :class="mealRules.newUI ? 'mealContent2' : 'mealContent'" v-show="mealList.length && !fromXBH && ['all', 'meal'].includes(isSelectCard)">
                    <div class="mealCateHead">外出用餐</div>
                    <component v-if="mealList.length" :is="isItem5Detail" :mealList="mealList" @open="open"></component>
                </div>
                <div :class="mealRules.newUI ? 'mealContent2 take_out' : 'mealContent'" v-show="takeoutList.length && ['all', 'takeOut'].includes(isSelectCard)">
                    <div class="mealCateHead">外卖</div>
                    <component :is="isItem7Detail" ref="item7" @openWaimaiPop="openWaimaiPop" @chooseHos="chooseHos" :takeoutList="takeoutList"></component>
                </div>
                <div :class="mealRules.newUI ? 'mealContent2 take_out' : 'mealContent'" v-show="marketList.length">
                    <div class="mealCateHead">商超便利</div>
                    <Supermarket ref="Supermarket" @openWaimaiPop="openWaimaiPop" :marketList="marketList" />
                </div>
                <NoData v-if="(!mealList.length && !takeoutList.length && !marketList.length) || (['meal'].includes(isSelectCard) && !mealList.length) || (['takeOut'].includes(isSelectCard) && !takeoutList.length)"></NoData>
            </div>
        </ScrollBar>
        <!-- <div class="addDemand" v-if="canNewItem.show && !mealRules.newUI" @click="openpOP" @touchmove="touchmove($event)">
            <Svgs :color="colors[this.event.tenant_code]" classes="mealOperationBtn" name="icontianjia2"></Svgs>
        </div> -->
        <div class="addDemand" v-if="canNewItem.show" @touchmove="touchmove($event)">
            <div class="addmeal" :style="{ display: addMealShow ? 'block' : 'none', height: addMealBoxHeight, top: addMealBoxTop }">
                <div class="addmeal_list" v-for="(item, index) in itemList" :key="index" @click="handlechannel(item.handle, item.itemType)">
                    <div class="meal_icon">
                        <Svgs :name="item.icon"></Svgs>
                    </div>
                    <p>
                        {{ item.txt }}
                    </p>
                </div>
            </div>
            <div @click="openpOP" class="addDemand_btn">
                <!-- <svg class="icon2" aria-hidden="true">
                    <use fill="#fff" xlink:href="#icon-tianjia3" />
                </svg> -->
                <!-- <Svgs color="#fff" classes="mealOperationBtn" name="icon-tianjia3"></Svgs> -->

                <svg class="icon" style="width: 0.3rem; height: 0.3rem" aria-hidden="true">
                    <use :fill="'#fff'" xlink:href="#icon-tianjia3"></use>
                </svg>
            </div>
        </div>
        <ItemPop ref="itemPop" :itemList="itemList" @openAd="openAd" @chooseHos="chooseHos" />
        <WaimaiPop ref="waimaiPop" />
        <AdPop ref="AdPop" />
        <HospitalPop @onOk="chooseHosRes" ref="hospitalPop" />
        <smart-pay :host="alipayHost" :orderData="voucherInput" :onComplete="completeAlipay" :onClose="closeAlipay" ref="aliPay"></smart-pay>
    </div>
</template>

<script>
import Item5Detail from "@/components/OrderList/Item5Detail.vue";
import Item7Detail from "@/components/OrderList/Item7Detail.vue";
import Item5Detail2 from "@/components/OrderList/Item5Detail2.vue";
import Item7Detail2 from "@/components/OrderList/Item7Detail2.vue";
import Supermarket from "@/components/OrderList/Supermarket.vue";
import ItemPop from "@/components/OrderList/ItemPop.vue";
import WaimaiPop from "@/components/OrderList/WaimaiPop.vue";
import AdPop from "@/components/OrderList/AdPop.vue";
import { miceService } from "@/service/miceService.js";
import { smartPay } from "smart-alipay";
import myAlipay from "@/components/Pay/myAlipay.js";
import { mapGetters, mapMutations } from "vuex";
import { baseUrl } from "@/service/baseUrl.js";
import { Colors } from "@/utils/colors.js";
import { SmartStorage } from "smart-core-util";
import { setCookie, getCookie } from "tiny-cookie";
import Dialog from "vant/lib/dialog";
import moment from "moment";
let wx = window.wx
// import wx from 'weixin-js-sdk'
export default {
    name: "OrderList",
    components: {
        Item5Detail,
        Item5Detail2,
        Item7Detail,
        Item7Detail2,
        Supermarket,
        ItemPop,
        smartPay,
        WaimaiPop,
        AdPop,
    },
    computed: {
        ...mapGetters(["event", "refresh", "dateObj", "memsg", "mealRules"]),
        isItem5Detail: {
            get: function () {
                var name = this.mealRules.newUI ? "Item5Detail2" : "Item5Detail";
                return name;
            },
        },
        isItem7Detail: {
            get: function () {
                var name = this.mealRules.newUI ? "Item7Detail2" : "Item7Detail";
                return name;
            },
        },
        isShowBatchApprovalBtn() {
            let flag = false;
            let data1 = moment(SmartStorage.get("eventData").dtEnd); //会议结束日期
            let date2 = moment(); //当前日期
            let day = date2.diff(data1, "day");
            flag = day >= 1;
            return ["novartis"].includes(this.event.tenant_code) && SmartStorage.get("eventData").extData?.isLargeMeeting && SmartStorage.get("eventData").userId == SmartStorage.get("userId") && flag;
        },
    },
    mixins: [myAlipay],
    data() {
        return {
            tips:[
                '* 加单需符合实际会议需求，同时注意审批过的金额限制',
                '* 美团餐厅，如需重新支付，请联系客服处理，01056304858（专属客户代码648）',
                '* 非美团餐厅，如需重新支付，请先联系商户退款。商户退款后刷新列表重新支付',
            ],
            fromXBH: false,
            dateList: [],
            mealDayList: [],
            mealList: [],
            takeoutList: [],
            marketList: [],
            canNewItem: {},
            itemList: [],
            loadType: "",
            listStyle: {},
            close: false,
            itemId: "",
            colors: {},
            marketShow: false,
            itemButtons: [],
            addMealShow: false,
            isSelectCard: "all",
        };
    },
    async created() {
        this.marketShow = SmartStorage.get("supermarket");
    },
    async mounted() {
        let that = this;
        document.addEventListener("click", this.addClick);
        if (SmartStorage.get("userId") == "93d10ec9-8a72-11ec-89d2-6a0616dfdee4" && document.getElementsByClassName("mealHead").length > 0) {
            document.getElementsByClassName("mealHead")[0].addEventListener("click", function (event) {
                that.$router.push({
                    path: "/getUserMedia",
                });
            });
        }
        if (process.env.NODE_ENV == "development" && document.getElementsByClassName("mealHead").length > 0) {
            let timer = null;
            let waitTime = 200;
            let lastTime = new Date().getTime();
            let count = 0;
            document.getElementsByClassName("mealHead")[0].addEventListener("click", function (event) {
                let currentTime = new Date().getTime();
                count = currentTime - lastTime < waitTime ? count + 1 : 1;
                lastTime = new Date().getTime();
                clearTimeout(timer);
                timer = setTimeout(function () {
                    clearTimeout(timer);
                    if (count > 3) {
                        that.$router.push({
                            path: "/getUserMedia",
                        });
                    }
                }, waitTime + 10);
            });
        }
        if (SmartStorage.get("isFromMore") || this.event.isFromMore || this.event.search) {
            return;
        }
        if (!this.event.tenant_code) {
            let eventData = {
                ...SmartStorage.get("eventData"),
                ...this.event,
            };
            this["EVENT"](eventData);
        }
        let _this = this;
        this.fromXBH = SmartStorage.get("isH5");
        this.colors = Colors;
        if (this.$route.query.dataCode && SmartStorage.get("eventData").showWaimai) {
            this.openWaimaiPop();
            let eventData = { ...SmartStorage.get("eventData"), showWaimai: false };
            this["EVENT"](eventData);
        }
        setTimeout(() => {
            _this.getpageData();
        }, 200);
        this.getPosition();
        if (["h5", "browser"].includes(SmartStorage.get("container"))) {
            var agent = navigator.userAgent.toLowerCase();
            var iphone = agent.indexOf("iphone");
            var ipad = agent.indexOf("ipad");
            if (iphone != -1 || ipad != -1) {
                window.addEventListener("pageshow", function (e) {
                    // 监听页面缓存，若缓存则刷新
                    if (e.persisted) {
                        _this.getpageData();
                    }
                });
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.addClick);
    },
    watch: {
        refresh: function (val, oldVal) {
            if (val) {
                this.getList();
            }
        },
    },
    methods: {
        ...mapMutations(["FRESH_LIST", "DATE_OBJ", "EVENT", "CURRENT_POSITION", "MEAL_RULES", "ORDER_DETAIL"]),
        GenerateWechatJsSDKParams(sid){
            let params = {
                sid: sid,
                currUrl: window.document.location.href
            }
            return new Promise((resolve,reject) => {
                miceService.GenerateWechatJsSDKParams(params).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        async getLocation(){
            let appId = 'ww8e54ffd2ad46e81e'
            let sid = '2160'
            // if(['会引擎-石药','会引擎-恩必普'].includes(this.event.tenant_name)){
            //     appId = 'ww8e54ffd2ad46e81e',
            //     sid = '2160'
            // }
            // if(this.event.tenant_name == '会引擎-huarun'){
            //     appId = 'wx859cba36dedb1a67'
            //     sid = '2163'
            // }
            // if (this.event.tenant_name == "会引擎-灵北") {
            //     appId = "wp2HVeDAAAD7KJBgbcl83wmoD-G3b0PA";
            //     sid = "2167";
            // }
            // if (this.event.tenant_code == "roche") {
            //     appId = "wx00a65cbbe46b0fb2";
            //     sid = "1102";
            // }
            let _this = this
            let wvRes = await this.GenerateWechatJsSDKParams(sid)
            wx.config({
                beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                // appId: 'ww8e54ffd2ad46e81e', // 必填，企业微信的corpIDww7beb2b180d97789b
                appId: appId, // 必填，企业微信的corpID  石药prd用石药appid
                timestamp: wvRes.timestamp, // 必填，生成签名的时间戳
                nonceStr: wvRes.nonceStr, // 必填，生成签名的随机串
                signature: wvRes.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });
            wx.error(function (res) {
                console.log('=-=-=-注入失败',res);
                alert(JSON.stringify(res));
            });
            wx.ready(function(){
                console.log('=-=-=-注入成功');
               wx.getLocation({
                        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                        success: function (res) {
                            var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                            var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                            var speed = res.speed; // 速度，以米/每秒计
                            var accuracy = res.accuracy; // 位置精度
                            alert(JSON.stringify(res));
                            console.log('=-=-=-定位成功',res);
                        },
                        fail: function(res) {
                            console.log('=-=-=-定位失败',res);
                            alert(JSON.stringify(res))
                        }
                    });
            });
        },
        batchApproval() {
            this.$router.push("/batchApproval");
        },
        addClick(e) {
            let addDemand = document.querySelector(".addmeal");
            if (addDemand && !addDemand.contains(e.target)) {
                this.addMealShow = false;
            }
        },
        handlechannel(name, itemType) {
            let params = {
                miceId: this.event.proposalId,
                itemType: itemType,
            };
            miceService.checkItemTypeLocked(params).then((res) => {
                if (res && res.success) {
                    this.addDemand(name);
                }
            });
        },
        addDemand(name) {
            console.log("==-=-=-", name);
            this["ORDER_DETAIL"]({});
            switch (name) {
                case "OutMealPlatform":
                    if(["roche"].includes(this.event.tenant_code) 
                        && (SmartStorage.get("eventData").typeDictTxt == "科室会" ||
                        SmartStorage.get("eventData").typeDictTxt == "院内会")){
                        Dialog.confirm({
                            message: '建议选择地理位置便于医疗卫生专业人士出席，符合罗氏标准的合理地点用餐。',
                        }).then(() => {
                            this.$router.push({
                                path: "/addMealDemand?shopType=平台到餐",
                            });
                        }).catch(()=> {
                            
                        })
                        return
                    }else{
                        this.$router.push({
                            path: "/addMealDemand?shopType=平台到餐",
                        });
                    }
                    break;
                case "OutMealMT":
                    this.$router.push({
                        path: "/addMealDemand?shopType=美团到餐",
                    });
                    break;
                case "OutMealSYT":
                    this.checkOpenPage();
                    break;
                case "Waimai":
                case "WaimaiEle":
                    // if(['novartis'].includes(this.event.tenant_code)) {
                    //     this.$emit('chooseHos')
                    // } else {
                    this.$router.push({ path: "/addTakeOutDemand" });
                    // }
                    break;
            }
            this.addMealShow = false;
        },
        openAd() {
            this.$refs.AdPop.openPop();   
        },
        async CheckUserAuth() {
            let params = {
                phoneNumber: SmartStorage.get("authMobile"),
            };
            return new Promise((resolve, reject) => {
                miceService
                    .CheckUserAuth(params)
                    .then((res) => {
                        if (res && res.success) {
                            resolve(res);
                        } else {
                            resolve({});
                        }
                    })
                    .catch((err) => {
                        reject(false);
                    });
            });
        },
        async GenerateUserAuthUrl() {
            let params = {
                sid: this.mealRules.sid,
                authMobile: SmartStorage.get("authMobile"),
            };
            return new Promise((resolve, reject) => {
                miceService
                    .GenerateUserAuthUrl(params)
                    .then((res) => {
                        if (res && res.success) {
                            resolve(res);
                        } else {
                            resolve({});
                        }
                    })
                    .catch((err) => {
                        reject(false);
                    });
            });
        },
        async GenerateMemberAuthUrl() {
            let params = {
                sid: this.mealRules.sid,
                authMobile: SmartStorage.get("authMobile"),
            };
            return new Promise((resolve, reject) => {
                miceService
                    .GenerateMemberAuthUrl(params)
                    .then((res) => {
                        if (res && res.success) {
                            resolve(res);
                        } else {
                            resolve({});
                        }
                    })
                    .catch((err) => {
                        reject(false);
                    });
            });
        },
        async getpageData() {
            if (SmartStorage.get("supermarket")) {
                this.itemButtons = await this.getButtonList();
                // this.getItemTimeList(20);
                this.checkCanNewItem();
                this.getList();
                this.getStyle();
            } else {
                // this.getItemTimeList();
                this.checkCanNewItem();
                this.getOptItems();
                this.getList();
                this.getStyle();
            }
        },
        getStyle() {
            let head_h;
            try {
                if (this.mealRules.newUI) {
                    head_h = document.getElementsByClassName("headContent4")[0].clientHeight;
                } else {
                    head_h = document.getElementsByClassName("headContent3")[0].clientHeight;
                }
            } catch (error) {
                console.log("====获取headContent元素高度失败", error);
            }
        },
        getPosition() {
            let _this = this;
            var map = new AMap.Map("container", {});
            AMap.plugin("AMap.Geolocation", () => {
                /* global AMap */
                var geolocation = new AMap.Geolocation({
                    timeout: 10000, //超过n秒后停止定位，默认：5s
                    useNative: true,
                    enableHighAccuracy: true, //是否使用高精度定位，默认:true
                    extensions: "all",
                });
                map.addControl(geolocation);
                geolocation.getCurrentPosition((status, result) => {
                    if (status == "complete") {
                        let city = result.addressComponent.city || result.addressComponent.province;
                        let address = {
                            latitude: result.position.lat,
                            longitude: result.position.lng,
                            name: (result.pois || [{ name: "" }])[0].name || result.formattedAddress,
                            city: city,
                        };
                        this["CURRENT_POSITION"](address);
                    }
                });
            });
        },
        openWaimaiPop() {
            this.$refs.waimaiPop.open();
        },
        // 选择医院
        chooseHos(close, itemId) {
            this.close = close;
            this.itemId = itemId || this.generateUUID();

            this.$refs.hospitalPop.openPop();
        },
        chooseHosRes(item) {
            let eventData = {
                itemId: this.itemId,
                hospitalGPS: {
                    longitude: item.el,
                    latitude: item.nl,
                    hospitalName: item.name,
                    city: item.city,
                },
            };
            this.$refs["item7"].goWaimai(eventData);
        },
        generateUUID() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        goWaimai(sessionId) {
            if (!this.close) {
                this.$refs.itemPop.open();
            }
            let _this = this;
            const options = {
                loadUrl: `${baseUrl.iWaimaiUrl}/ele/shopList?itemId=${this.itemId}&tenant_code=${SmartStorage.get("eventData").tenant_code}&appType=iMeal&ProposalId=${this.event.proposalId || SmartStorage.get("eventData").proposalId}&sessionId=${sessionId}&rnd=${new Date().getTime()}`,
                callback: function () {
                    _this["FRESH_LIST"](true);
                },
            };
            this.iJsBridge.openWebapp(options);
        },
        touchmove(event) {
            let header_h;
            if (this.mealRules.newUI) {
                header_h = document.getElementsByClassName("headContent4")[0].clientHeight;
            } else {
                header_h = document.getElementsByClassName("headContent3")[0].clientHeight;
            }
            event.preventDefault(); //阻止默认事件传播
            event.stopPropagation();
            var _this = this;
            setTimeout(function () {
                var _circle = event.targetTouches[0];
                var creat = document.getElementsByClassName("addDemand")[0];
                var _clientWidth = document.documentElement.clientWidth;
                var _clientHeight = document.documentElement.clientHeight;
                if (_circle.pageX > 28 && _circle.pageX < _clientWidth - 28) {
                    creat.style.left = -28 + _circle.pageX + "px";
                }
                if (_circle.pageY > header_h + 28 && _circle.pageY < _clientHeight - 28) {
                    creat.style.top = -28 + _circle.pageY + "px";
                }
            }, 0);
        },
        loadTop() {
            this.loadType = "top";
            if (SmartStorage.get("supermarket")) {
                // this.getItemTimeList(20);
                this.checkCanNewItem();
                this.getList();
            } else {
                // this.getItemTimeList();
                this.checkCanNewItem();
                this.getList();
            }
            setTimeout(() => {
                this.loadType = "";
            }, 1000);
        },
        getItemTimeList(ItemTypes) {
            if (this.mealRules.newUI) {
                return;
            }
            let params = {
                proposalId: SmartStorage.get("eventData").proposalId,
            };
            ItemTypes ? (params.ItemTypes = ItemTypes) : "",
                miceService.getItemTimeList(params).then((res) => {
                    if (res && res.success) {
                        this.dateList = [];
                        this.mealDayList = [];
                        this.dateList = res.content;
                        this.mealDayList = this.dateList[this.dateObj.monthOn].timeList;
                    }
                });
        },
        changeMonth(index) {
            this["DATE_OBJ"]({
                ...this.dateObj,
                monthOn: index,
            });
            this.mealDayList = this.dateList[index].timeList;
        },
        changeDay(index, m) {
            if (!m.isForbidden) {
                return;
            } else {
                this["DATE_OBJ"]({
                    ...this.dateObj,
                    dayOn: m.curDay,
                });
                this.getList();
            }
        },
        isWx() {
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf("micromessenger") != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        },
        async openpOP() {
            // this.getLocation()
            if (SmartStorage.get("supermarket")) {
                this.$router.push("addSuperMarket");
                return;
            }
            if (this.mealRules && this.mealRules.alipay) {
                let path = "/alipayAuthorize";
                this.isWx() ? (path = `/alipayAuthorize?authorize=true&sessionId=${SmartStorage.get("sessionId")}&sid=${this.mealRules.sid}&userId=${this.memsg.userId}&rnd=${new Date().getTime()}`) : "";
                let res = await this.CheckUserAuth();
                if (!res.content.isUserAuth || !res.content.isMemberAuth) {
                    this.$router.push({
                        path: path,
                    });
                    return;
                }
            }
            if (this.mealRules.newUI) {
                this.addMealShow = !this.addMealShow;
                return;
            }
            if (this.canNewItem.forbidden) {
                // alert(this.canNewItem.tooltip);
                Dialog({
                    title: "提 示",
                    message: this.canNewItem.tooltip,
                });
                return;
            }
            this.$refs.itemPop.open();
        },
        checkCanNewItem() {
            let params = {
                db: "smartx_tpm",
                collection: "cfg_category_btns",
                filter: {
                    Code: { $in: ["Meal_AddBtn"] },
                },
                projection: {
                    _id: 0,
                    _description: 0,
                },
                sort: {
                    dspOrder: 1,
                },
                Version: 2.0,
                ContextDataKeys: {
                    Version: 2.0,
                    MiceId: SmartStorage.get("eventData").proposalId,
                    retractFlag: SmartStorage.get("retractFlag") || false
                },
            };
            miceService.newItem(params).then((res) => {
                if (res && res.success) {
                    this.canNewItem = res.content[0].Result;
                }
            });
        },
        getMealList(params) {
            miceService.getMealList(params).then((res) => {
                if (res && res.success) {
                    let index = 1;
                    res.content.map((item) => {
                        item.itsItemData.shopImg = this.urlConversion(item.itsItemData.shopImg);
                        if (item.isExtra && item.changeShop) {
                            for (let i = 0; i < item.itsButtons.length; i++) {
                                if (item.itsButtons[i].txt == "详情") {
                                    index = i;
                                }
                            }
                            item.itsButtons.splice(index, 0, {
                                forbidden: false,
                                msg: null,
                                processor: "changeShop",
                                txt: "换餐厅",
                            });
                        }
                    });
                    this.mealList = res.content;
                    this["FRESH_LIST"](false);
                }
            });
        },
        urlConversion(path) {
            if (!path) {
                return "";
            }
            let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
            path = path.replace(reg, "https://$2$3$4$5$6");
            return path;
        },
        async getMarList(params) {
            params.ItemTypes = 20;
            miceService.getMarketList(params).then(async (res) => {
                if (res && res.success) {
                    let content = res.content;
                    for (let item of content) {
                        if (item.status == "-1" && !item.extOrderId) {
                            item.itsButtons = [];
                        }
                        if (item.soucreCode == "JD") {
                            item.itsButtons = [];
                            for (let item2 of this.itemButtons) {
                                if (item2.statusRule.includes(item.status)) {
                                    SmartStorage.get("eventData").status != 100 ? item.itsButtons.push(item2) : item2.processor != "JDcancelOrder" && item.itsButtons.push(item2);
                                }
                            }
                        }
                    }
                    this.marketList = content;
                    this["FRESH_LIST"](false);
                }
            });
        },
        // 获取列表按钮
        getButtonList() {
            let params = {
                collection: "cfg-imeal-button",
                useCaching: false,
                cachingKey: "",
                filter: {
                    type: "itemButtons",
                },
            };
            return new Promise((resolve, reject) => {
                miceService
                    .getOptItems(params)
                    .then((res) => {
                        if (res && res.success && res.content) {
                            resolve(res.content);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getTakeoutList(params) {
            miceService.getTakeoutList(params).then((res) => {
                if (res && res.success) {
                    this.takeoutList = res.content;
                    this["FRESH_LIST"](false);
                }
            });
        },
        getList() {
            let params = {
                proposalId: SmartStorage.get("eventData").proposalId,
            };
            if (SmartStorage.get("eventData").tenant_code == "novartis") {
                params = {
                    ...params,
                    withTaskId: true,
                    onlyShowConfirm: false,
                };
            }
            if (this.dateObj.dayOn && this.dateObj.dayOn != "all") {
                params.DtStart = this.dateObj.dayOn;
            }

            if (SmartStorage.get("supermarket")) {
                this.getMarList(params);
                return;
            } else {
                this.getMealList(params);
                this.getTakeoutList(params);
            }
        },
        getOptItems() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    eventType: { $in: [SmartStorage.get("eventData").typeDictTxt || "*", "*"] },
                    eventStatus: { $in: [SmartStorage.get("eventData").statusTxt || "*", "*"] },
                    eventPlace: { $in: ["*", "*"] },
                    "cities.city": {
                        $in: [SmartStorage.get("eventData").cityDictVal || "*", "*"],
                    },
                },
                projection: {
                    txt: 1,
                    handle: 1,
                    icon: 1,
                    order: 1,
                    itemType: 1,
                },
            };
            if (this.event.tenant_name == "会引擎-huarun") {
                params.filter.eventType = { $in: ["*"] };
            }
            if (SmartStorage.get("eventData").tenant_code == "crossevent" && !["会引擎-huarun", "会引擎-协办会"].includes(this.event.tenant_name)) {
                params.filter.tenantId = getCookie("tenant_id");
            } else {
                params.filter.tenantCode = SmartStorage.get("eventData").tenant_code;
            }
            // 石药抗肿瘤配置外出
            if (getCookie("tenant_id") == "7faf5393-8a70-11ec-89d2-6a0616dfdee4") {
                params.filter.product = { $in: [(SmartStorage.get("eventData").extData.userInfo || "*").product || "*", "*"] };
                params.filter.company = { $in: [JSON.parse(this.memsg.profileData).company, "*"] }
            }
            // 石药药品配置外出
            if (getCookie("tenant_id") == "207d3942-395e-11ed-90ea-863f87f6346f") {
                params.filter.miceMode = { $in: [SmartStorage.get("eventData").extData.miceMode || "*", "*"] };
            }
            miceService.getOptItems(params).then((res) => {
                if (res && res.success && res.content) {
                    let arr = res.content || [];

                    if ((SmartStorage.get("tenant_code") != "crossevent" && SmartStorage.get("isH5")) || SmartStorage.get("type") == "iwaimai") {
                        arr.sort(this.compare("order")).map((item) => {
                            if (item.handle == "Waimai") {
                                this.itemList.push(item);
                            }
                        });
                    } else {
                        this.itemList = arr.sort(this.compare("order"));
                    }

                    this.addMealBoxHeight = 0.5 * this.itemList.length + "rem";
                    this.addMealBoxTop = -0.625 * this.itemList.length + "rem";
                }
            });
        },
        // 比较
        compare(property) {
            return function (a, b) {
                let value1 = a[property];
                let value2 = b[property];
                return value1 - value2;
            };
        },
        back() {
            const isH5 = SmartStorage.get("isH5");
            const eventData = SmartStorage.get("eventData");
            const ieventUrl = SmartStorage.get("Uris").Uris["webapp-ievent"];
            const container = SmartStorage.get("miniprogram");
            if(container){
                wx.miniProgram.redirectTo({
                    url: '/pages/eventDetail' //小程序必须有该目录
                })
                return
            }
            SmartStorage.remove("supermarket");
            if (isH5) {
                SmartStorage.remove("supermarket");
                let webappUrl = `${ieventUrl}/opConfirmPrice?requireId=${eventData.requireId}&miceId=${eventData.miceId}`;
                window.open(webappUrl, "_self");
                return;
            }
            var sendData = JSON.stringify({ handle: "reload_event_detail" });
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
            } else {
                this.iJsBridge.call({
                    method: "SMGeneral.setResultToNative",
                    postData: sendData,
                });
                this.iJsBridge.close();
            }
        },
    },
};
</script>

<style lang="scss">
@import "OrderList";
.cardChange {
    position: fixed;
    display: flex;
    padding: 0.05rem 0;
    font-weight: 500;
    color: #909399;
    background: #fff;
    width: 100%;
    z-index: 1;
    p {
        font-size: 0.15rem;
    }
    div {
        min-width: 0.8rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            min-width: 0.14rem !important;
            width: 0.14rem !important;
            height: 0.04rem;
            background: var(--themeColor);
            border-radius: 2px;
        }
    }
    .isSelectCard {
        font-weight: bold;
        color: #13161b;
        p {
            height: 0.24rem;
            display: inline-block;
            font-size: 0.16rem;
        }
    }
}
</style>
